import {apiUrls} from "../../assets/api/apiURL";

export const cartDataFetch = (setCart) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache'
    }

    fetch(`${apiUrls.mainUrl}${apiUrls.cart.cartData}`,options)
        .then((res) => res.json())
        .then((json) => {
            setCart(json.cart)
        })
}

export const addProductToCart = (params) => {
    const formData = new FormData();
    formData.append("id_product", params.product.id);
    formData.append("quantity", params.product.quantity.toString());

    if(params.functions.type === "productPage" || params.functions.type === "cartPage") {
        if(params.product.extensions.cert) {
            formData.append("extensions", "1");
        } else {
            formData.append("extensions", "false");
        }
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: formData
    }

    fetch(`${apiUrls.mainUrl}${apiUrls.cart.add}`,options)
        .then((res) => res.json())
        .then((json) => {
            if(json.status === true) {
                params.functions.updateCartData()
                if(params.functions.type === "productPage") {
                    params.functions.setMessage(1)
                    params.functions.setMessageOpen(true)
                } else if(params.functions.type === "singleItem") {
                    params.functions.updateCurrentAddProduct(params.product)
                    params.functions.updateShowInfoCartError(0)
                    params.functions.setAdded(2)
                } else if(params.functions.type === "cartPage") {
                    params.functions.hideLoader(false, params.product)
                }
            } else if(json.status === false) {
                if(params.functions.type === "productPage") {
                    params.functions.setMessage(4)
                    params.functions.setMessageOpen(true)
                } else if(params.functions.type === "singleItem") {
                    params.functions.updateCurrentAddProduct(params.product)
                    params.functions.updateShowInfoCartError(2)
                    params.functions.setAdded(3)
                } else if(params.functions.type === "cartPage") {
                    params.functions.hideLoader(true, params.product)
                }
            }
        })
}

export const updateProductInCart = (params) => {
    const formData = new FormData();
    formData.append("id_product", params.product.id);
    formData.append("quantity", params.product.quantity.toString());

    if(params.functions.type === "productPage") {
        if(params.product.extensions.cert) {
            formData.append("extensions", "1");
        } else {
            formData.append("extensions", "false");
        }
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: formData
    }

    fetch(`${apiUrls.mainUrl}${apiUrls.cart.update}`,options)
        .then((res) => res.json())
        .then((json) => {
            if(json.status === true) {
                params.functions.updateCartData()
                if(params.functions.type === "productPage") {
                    params.functions.setMessage(1)
                    params.functions.setMessageOpen(true)
                } else if(params.functions.type === "singleItem") {
                    params.functions.updateCurrentAddProduct(params.product)
                    params.functions.updateShowInfoCartError(0)
                    params.functions.setAdded(2)
                }
            } else if(json.status === false) {
                if(params.functions.type === "productPage") {
                    params.functions.setMessage(4)
                    params.functions.setMessageOpen(true)
                } else if(params.functions.type === "singleItem") {
                    params.functions.updateCurrentAddProduct(params.product)
                    params.functions.updateShowInfoCartError(2)
                    params.functions.setAdded(3)
                }
            }
        })
}

export const updateProductsInCart = (params) => {

    let updateData = []

    params.products.forEach((item) => {
        const formData = new FormData();
        formData.append("id_product", item.id);
        formData.append("quantity", item.quantity.toString());

        if(item.extensions.cert) {
            formData.append("extensions", "1");
        } else {
            formData.append("extensions", "false");
        }

        const options = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            cache: 'no-cache',
            body: formData
        }
        updateData.push({options : options, item : item})
    });

    const update = async () => {
        return await Promise.all(
            updateData.map(data => fetch(`${apiUrls.mainUrl}${apiUrls.cart.update}`,data.options).then(res => res.json().then(json => {
                    return {
                        response : json,
                        item : data.item
                    }
                })
            ))
        )
    }

    update().then(responses => {
        setTimeout(() => {

            let fail = []

            responses.forEach((item) => {
                if(item.response.status === false) {
                    fail.push(item.item)
                }
            })

            params.functions.updateCartData()

            if(fail.length === 0) {
                params.functions.hideLoader()
            } else {
                params.functions.hideLoader(fail)
            }
        },200)
    })
}

export const deleteProductFromCart = (item, setLoadCart , hideLoader) => {
    const formData = new FormData();
    formData.append("id_product", item.id_product);

    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        cache: 'no-cache',
        body: formData
    }

    fetch(`${apiUrls.mainUrl}${apiUrls.cart.delete}`,options)
        .then((res) => res.json())
        .then((json) => {
            if(json.status === "true") {
                setLoadCart()
                if(hideLoader) {
                    hideLoader(json.status,item)
                }
            } else {
                setLoadCart()
                if(hideLoader) {
                    hideLoader(json.status,item)
                }
            }
        })
}