import React, {useEffect, useRef} from 'react';
import styles from './loader.module.css'

export function Loader(props) {

    const loaderRef = useRef()

    useEffect(() => {

        if(props.data && document.getElementById("loader")) {
            loaderRef.current.style.transition = "0.4s all"
            if(props.scroll !== false) {
                window.scrollTo(0,0)
            }
            setTimeout(() => {
                loaderRef.current.style.opacity = "0"
                loaderRef.current.style.visibility = "hidden"
            },1)
        } else {
            loaderRef.current.style.transition = "0s all"
            loaderRef.current.style.opacity = "1"
            loaderRef.current.style.visibility = "visible"
        }

    },[props.data, props.scroll])

    useEffect(() => {
        if(props.builder) {
            loaderRef.current.style.visibility = "visable"
        }
    },[props.builder])

    return (
        <div ref={loaderRef} className={`${styles.loader} ${props.data ? styles.loaderHide : ''}`} id={"loader"}>
            <div className={styles.wrapperMain}>
                <div className={styles.logo}/>
                <div className={`${styles.ldsEllipsis} ${props.builder ? styles.ldsEllipsisBuilder : ''}`}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}