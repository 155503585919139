export const apiUrls = {
    mainUrl : "https://api.monety-inwestycyjne.pl/api/",
    menu : "pages/main/menu?expand=child.items",
    search : "products/product",
    mainPage : "pages/main",

    blog : {
        blogList : "blog/blog",
        blogCategory : "blog/blog-categories",
        blogPost : "blog/blog",
    },

    textPages : {
        pages : "pages/pages",
    },

    products : {
        category : "products/product",
        product : "products/product"
    },

    cart : {
        cartData : "cart",
        add : "cart/add-product",
        delete : "cart/delete-product",
        update : "cart/update-product"
    },

    order : {
        shipping : "orders/shipping",
        payments : "orders/payments",
        showOver : "checkout/limit"
    },

    checkout : "checkout",

    user : {
        login : "auth/login",
        register: "users/create",
        lostPassword : "users/reset-password",
        logOut : "auth/logout",
        token : "auth/refresh-token",
        details : "users/account",
        orders : "users/orders",
        order : "users/view-order",
        addresses : "users/addresses",
        changeAddresses : "users/edit-addresses",
        changePassword : "users/change-password",
        changeUserData : "users/edit-account"
    },

    graphs : "metals/graphs",
    departament :  "departments/department"
};