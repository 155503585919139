import React, {useEffect, useState, useRef , createContext} from 'react';
import {cartDataFetch} from "../CartFunctions/cartFunctions";
import {apiUrls} from "../../assets/api/apiURL";

export const ThemeContext = createContext({
    cartData : [],
    currentAddProduct : null,
    showInfoCart: null,
    showInfoCartError: null,
    shippingValue : null,
    orderReceivedData : null,
    menuData: null,
    auth: null,
});

const ThemeContextProvider = props => {
    const [cartData, setCartData] = useState(null)
    const [currentAddProduct, setCurrentAddProduct] = useState(null)
    const [showInfoCart, setShowInfoCart] = useState(false)
    const [showInfoCartError, setShowInfoCartError] = useState(0)
    const [shippingValue, setShippingValue] = useState(null)
    const [orderReceivedData, setOrderReceivedData] = useState(null)
    const [menuData, setMenuData] = useState(null)
    const [auth, setAuth] = useState(null)

    const timer = useRef(null);
    const timerSecond = useRef(null)

    const updateCartData = () => {
        cartDataFetch(setCartData)
    }
    const productInCart = (id) => {
        if (cartData.products.find(element => element.id_product === id)) {
            return cartData.products.find(element => element.id_product === id)
        } else {
            return 0
        }
    }

    const updateCurrentAddProduct = (product) => {
        clearInterval(timer.current);
        clearInterval(timerSecond.current);
        setShowInfoCart(true)
        setCurrentAddProduct(product)
    }

    const updateShowInfoCartError = (number) => {
        setShowInfoCartError(number)
    }

    const updateShippingValue = (item) => {
        setShippingValue(item)
    }

    const updateOrderReceivedData = (item) => {
        setOrderReceivedData(item)
    }

    const updateMenuData = (item) => {
        setMenuData(item)
    }

    const updateAuth = (item) => {
        setAuth(item)
    }

    const refresh = (setData) => {

        fetch(`${apiUrls.mainUrl}${apiUrls.user.token}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            cache: 'no-cache',
        })
            .then((res) => res.json())
            .then((json) => {
                if(json.statusCode !== 401) {
                    setAuth(json.token)
                    if(setData) {
                        setData([])
                    }
                } else {
                    setAuth(false)
                    if(setData) {
                        setData([])
                    }
                }
            })
    }

    const logOut = () => {
        if(auth) {
            const formData = new FormData();

            formData.append("token", auth.toString());

            fetch(`${apiUrls.mainUrl}${apiUrls.user.logOut}`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                cache: 'no-cache',
                body: formData
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json === true) {
                        refresh()
                        cartDataFetch(setCartData)
                    }
                })

        }
    }

    useEffect(() => {
        cartDataFetch(setCartData)
    },[])

    useEffect(() => {
        if(currentAddProduct) {
            timer.current = setTimeout(() => {
                setShowInfoCart(false)
                timerSecond.current = setTimeout(() => {
                    setCurrentAddProduct(null)
                    setShowInfoCartError(0)
                },301)
            },2500)
        }
    },[currentAddProduct])

    return(
        <ThemeContext.Provider value={{
            cartData: cartData,
            currentAddProduct: currentAddProduct,
            showInfoCart : showInfoCart,
            showInfoCartError: showInfoCartError,
            shippingValue : shippingValue,
            orderReceivedData : orderReceivedData,
            menuData : menuData,
            auth: auth,
            updateCartData,
            productInCart ,
            updateCurrentAddProduct,
            updateShowInfoCartError,
            updateShippingValue,
            updateOrderReceivedData,
            updateMenuData,
            updateAuth,
            logOut,
            refresh
        }}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider;