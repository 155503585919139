import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import slug from "slug";

import { Loader } from "../../Components/Loader/loader";
import queryString from "query-string";
import { apiUrls } from "../../assets/api/apiURL";

import "../../assets/styles/index.scss";
import { ThemeContext } from "../../Components/Context/context";

const Header = lazy(() => import("../Header/header"));
const MainPage = lazy(() => import("../MainPage/mainPage"));
const ProductPage = lazy(() => import("../ProductPage/productPage"));
const CategoryPage = lazy(() => import("../CategoryPage/categoryPage"));
const SearchPage = lazy(() => import("../SearchPage/searchPage"));
const CartPage = lazy(() => import("../CartPage/cartPage"));
const OrderPage = lazy(() => import("../OrderPage/orderPage"));
const LoginPages = lazy(() => import("../LoginPages/loginPages"));
const TekstPages = lazy(() => import("../TekstPages/tekstPages"));
const OrderReceivedPage = lazy(() => import("../OrderReceivedPage/orderReceivedPage"));

const App = () => {
    const showContextRef = useRef(useContext(ThemeContext));
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const [tekstPages, setTekstPages] = useState(null);

    const tekstPagesWrite = () => {
        let tekst = "";

        tekstPages.forEach((item, index) => {
            if (index !== 0) {
                tekst = tekst + "|" + slug(item.url);
            } else {
                tekst = slug(item.url);
            }
        });

        return tekst;
    };

    useEffect(() => {
        fetch(`${apiUrls.mainUrl}${apiUrls.textPages.pages}`)
            .then((res) => res.json())
            .then((json) => {
                setTekstPages(json);
            });
    }, []);

    useEffect(() => {
        fetch(`${apiUrls.mainUrl}${apiUrls.menu}`)
            .then((res) => res.json())
            .then((json) => {
                showContextRef.current.updateMenuData(json);
            });
    }, []);

    return (
        <Suspense fallback={<Loader builder={true} />}>
            {tekstPages ? (
                <>
                    <Switch>
                        <Route exact path={"/"}>
                            <Header />
                            {queryParams.s || queryParams.s === "" ? <SearchPage /> : <MainPage />}
                        </Route>

                        <Route exact path={`/kategoria/:id`}>
                            <Header />
                            <CategoryPage />
                        </Route>

                        <Route exact path={`/produkt/:id`}>
                            <Header />
                            <ProductPage />
                        </Route>

                        <Route path={`/my-account/`}>
                            <Header />
                            <LoginPages />
                        </Route>

                        <Route exact path={`/cart/`}>
                            <Header />
                            <CartPage />
                        </Route>

                        <Route exact path={`/checkout/`}>
                            <Header />
                            <OrderPage />
                        </Route>

                        <Route exact path={`/checkout/order-received/`}>
                            <Header />
                            <OrderReceivedPage />
                        </Route>

                        <Route exact path={`/:url(${tekstPagesWrite()})/`}>
                            <Header />
                            <TekstPages data={tekstPages} />
                        </Route>

                        <Route exact path={`*`}>
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </>
            ) : null}
        </Suspense>
    );
};

function Builder() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default Builder;
